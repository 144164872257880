<template>
    <section>
        <h2 class="mb-4">Seleziona il tipo di progetto da realizzare</h2>
        <b-row class="p-5 justify-content-center">
            <b-col class="text-center p-2" md="6" v-if="['utilizzatore'].includes(userData.account_type_code)">
                <b-card class="p-3">
                    <div class="d-flex flex-column">
                        <img class="img-type" src="@/assets/images/analisi-impianto.jpg">
                        <div class="text-right">
                            <small>Crediti residui: <span>{{ credits_maintenance > 200 ? 'Illimitati' : credits_maintenance }}</span></small>
                        </div>
                        <b-button :disabled="credits_maintenance == 0" @click="$router.push('new_project?project_type=2')"
                            variant="primary" class="mt-3">Analisi impianto</b-button>
                    </div>
                </b-card>
            </b-col>
            <b-col class="text-center p-2" md="6" v-if="['installatore', 'installatore_manutentore'].includes(userData.account_type_code)">
                <b-card class="p-3">
                    <div class="d-flex flex-column">
                        <img class="img-type" src="@/assets/images/nuovo-impianto.jpg">
                        <div class="text-right">
                            <small>Crediti residui: <span>{{ credits_new > 200 ? 'Illimitati' : credits_new }}</span></small>
                        </div>
                        <b-button :disabled="credits_new == 0" @click="$router.push('new_project?project_type=1')"
                            variant="primary" class="mt-3">Nuovo impianto</b-button>
                    </div>
                </b-card>
            </b-col>
            <b-col class="text-center p-2" md="6" v-if="['manutentore', 'installatore_manutentore'].includes(userData.account_type_code)">
                <b-card class="p-3">
                    <div class="d-flex flex-column">
                        <img class="img-type" src="@/assets/images/manutenzione-impianto.jpg">
                        <div class="text-right">
                            <small>Crediti residui: <span>{{ credits_maintenance > 200 ? 'Illimitati' : credits_maintenance }}</span></small>
                        </div>
                        <b-button :disabled="credits_maintenance == 0"
                            @click="$router.push('new_project_maintenance')" variant="primary" class="mt-3">
                            Manutenzione impianto
                        </b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
} from "bootstrap-vue";
import { Requests } from "@/api/requests";

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardText,
        BButton,
    },

    created() {
        const self = this;
        Requests.getUser().then((data) => {
            localStorage.setItem('user', JSON.stringify(data.me.payload));
            self.userData = data.me.payload;
        });
    },

    data: function () {
        return {
            userData: null,
        }
    },

    computed: {
        credits_new: function () {
            return this.userData?.bought_credit_new ?? 0;
        },
        credits_maintenance: function () {
            return this.userData?.bought_credit_maintenance ?? 0;
        },
    }

}
</script>

<style>
.img-type {
    width: 50%;
    margin: 0 auto;
    padding-bottom: 30px;
}

small > span {
    font-weight: bold;
    color: #018001;
}
</style>
